.bg-white {
  background-color: $white;
}

.text-red {
  color: $red;
}

.text-center {
  text-align: center;
}
