//==============================
// GLOBAL
//==============================
body {
  background-color: $offwhite2;
}

.section-wrapper {
  padding-bottom: 80px;
  padding-top: 80px;

  &.is-light {
    background: $white;
  }

  @include mobile {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.section-title {
  margin-bottom: 40px;
  text-align: center;

  &.is-single {
    margin-bottom: 0;
  }

  h2 {
    line-height: 1;
  }

  h4,
  h5 {
    margin-top: 24px;
  }

  @include mobile {
    margin-bottom: 0;
  }
}

.section-content {
  h2 {
    margin-bottom: 16px;
  }
}

#main {
  margin-top: 76px;
}

//==============================
// HEADER
//==============================
#header {
  background: $offwhite3;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  .navbar-nav {
    .nav-link {
      color: $dgrey;
      @include twebMedium;
      text-transform: uppercase;

      &.active {
        color: $blue;
      }
    }
  }
}

//==============================
// FOOTER
//==============================
.footlinks {
  background: $dgrey;
  color: $white;
  padding: 80px 0;

  &--content {
    h4 {
      color: $offwhite2;
      font-weight: 600;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 8px;
      }

      &.list-links {
        li {
          position: relative;

          a:before {
            color: $yellow;
            content: "\f054";
            font-family: $fap;
            @include fontawesome;
            margin-right: 8px;
          }
        }
      }

      &.list-social {
        li {
          display: inline;
          margin-right: 16px;

          i {
            font-size: 40px;
          }
        }
      }
    }

    a {
      color: $white;
      opacity: 1;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @include mobile {
    padding: 40px 0;

    &--content {
      border-bottom: 1px solid $lgrey;
      margin-bottom: 24px;
      padding-bottom: 24px;

      &.is-last {
        border: 0;
        margin: 0;
        padding: 0;
      }

      ul {
        &.list-social {
          li {
            i {
              font-size: 32px;
            }
          }
        }
      }
    }
  }
}

.copyright {
  background: $black;
  color: $white;
  font-size: 14px;
  padding: 24px 0;

  a {
    color: $white;
    opacity: 1;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }

  @include mobile {
    text-align: center;
  }
}

//==============================
// Home: HERO
//==============================
.carousel--hero {
  .carousel-item {
    height: calc(100vh - 150px);

    .carousel-caption {
      background: rgba(0, 0, 0, 0.8);
      border-top: 4px solid $yellow;
      left: 18%;
      margin-bottom: 80px;
      right: 18%;
      padding: 24px 24px 16px;
      text-align: left;
    }
  }

  .btn-outline-primary {
    color: $yellow;
    border-color: $yellow;

    &:hover {
      color: $black;
      background-color: $yellow;
    }
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 4rem;
    width: 4rem;
  }

  @include mobile {
    .carousel-item {
      height: calc(100vh - 100px);

      .carousel-caption {
        display: block !important;
        left: var(--bs-gutter-x, 0.75rem);
        right: var(--bs-gutter-x, 0.75rem);
      }

      .carousel-item--image {
        width: auto !important;
      }
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      display: none;
    }
  }
}

//==============================
// Home: INTRO
//==============================
.intro {
  background: $white;

  &--left {
    h3 {
      color: $red;
    }
  }

  @include mobile {
    text-align: center;

    &--left {
      margin-bottom: 40px;
    }
  }
}

//==============================
// Home: SERVICES
//==============================
.services {
  &--card {
    background: $white;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }

  .card-content {
    padding: 24px 48px;
    text-align: center;

    .btn {
      margin-top: 16px;
    }
  }
}

//==============================
// Home: How To Be Our Client
//==============================
.steps {
  .steps-card {
    border: 1px solid $yellow;
    border-radius: 4px;
    margin-bottom: 24px;

    &:last-child {
      margin: 0;
    }

    > div {
      padding: 16px;
    }

    &--no {
      align-items: center;
      background: $yellow;
      color: $black;
      display: flex;
      @include dosis;
      justify-content: center;
      width: 20%;
    }

    &--text {
      width: 80%;
    }
  }

  @include mobile {
    margin-top: 40px;
  }
}

//==============================
// Testimonials
//==============================
.testimonials {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  min-height: 180px;
  padding: 24px;
  position: relative;
  text-align: center;

  span {
    display: block;
    @include dosisLight;
    font-style: italic;
    position: relative;

    &:before,
    &:after {
      color: $lgrey;
      font-size: 24px;
      line-height: 1;
    }

    &:before {
      content: "“";
    }

    &:after {
      content: "”";
    }
  }
}

//==============================
// Inner: BREADCRUMBS
//==============================
.breadcrumbs {
  background: $blue;
  color: $white;
  padding: 18px 0;

  &--inner {
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
    }

    .links {
      a {
        color: $white;
        line-height: 1;
        opacity: 0.8;
        text-decoration: none;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

//==============================
// Inner: CARDS
//==============================
.column-card {
  background: $white;
  @include boxShadow;
  margin-top: 40px;

  .card-content {
    padding: 24px 48px;
    text-align: center;
  }
}

//==============================
// Inner: PAGE CONTENT
//==============================
.page-content {
  h3 {
    margin-bottom: 16px;
  }

  h5 {
    @include twebMedium;
    margin-bottom: 16px;
  }

  @include mobile {
    img {
      margin-bottom: 24px;
    }
  }
}

//==============================
// Inner: MAP
//==============================
.map-fluid {
  height: 360px;
  overflow: hidden;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.contact {
  &-box {
    > .container {
      background: $white;
      @include boxShadow;
    }

    &.is-form {
      padding-top: 0;

      form {
        padding: 24px 24px 32px;
      }
    }
  }

  &-card {
    padding: 24px 0;
  }

  &-icon {
    border: 2px solid $red;
    border-radius: 50%;
    height: 42px;
    margin-right: 16px;
    padding: 8px 0;
    text-align: center;
    width: 42px;

    i {
      color: $red;
      font-size: 20px;
    }
  }

  &-text {
    h5 {
      font-weight: 600;
    }

    p {
      margin: 0;
    }

    a {
      color: $black;
      opacity: 1;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

//==============================
// Testimonials
//==============================
.service-area {
  background: $yellow;
  color: $black;
  font-size: 20px;
  min-height: 80px;
  padding: 24px;
  text-align: center;
}
