@mixin mobile {
  @media ( max-width: #{$tablet-width - 1px} ) {
    @content;
  }
}

@mixin tablet {
  @media ( min-width: #{$tablet-width} ) and ( max-width: #{$desktop-width - 1px} ) {
    @content;
  }
}

@mixin dosis {
  font-family: $dosis;
  font-weight: 400;
}

@mixin dosisLight {
  font-family: $dosis;
  font-weight: 300;
}

@mixin dosisMedium {
  font-family: $dosis;
  font-weight: 600;
}

@mixin tweb {
  font-family: $tweb;
  font-weight: 400;
}

@mixin twebMedium {
  font-family: $tweb;
  font-weight: 600;
}

@mixin twebBold {
  font-family: $tweb;
  font-weight: 700;
}

@mixin fontawesome {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin boxShadow {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
