@charset "UTF-8";
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Dosis", sans-serif !important;
  font-weight: 400;
}

h1,
.h1 {
  font-size: 48px;
}
@media (max-width: 766px) {
  h1,
.h1 {
    font-size: 40px;
  }
}

h2,
.h2 {
  font-size: 40px;
}
@media (max-width: 766px) {
  h2,
.h2 {
    font-size: 32px;
  }
}

h3,
.h3 {
  font-size: 32px;
}
@media (max-width: 766px) {
  h3,
.h3 {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-size: 24px;
}
@media (max-width: 766px) {
  h4,
.h4 {
    font-size: 20px;
  }
}

h5,
.h5 {
  font-size: 20px;
}
@media (max-width: 766px) {
  h5,
.h5 {
    font-size: 18px;
  }
}

body {
  font-family: "Titillium Web", sans-serif !important;
  font-weight: 400;
}

.btn {
  opacity: 1;
}
.btn:hover {
  opacity: 0.9;
}

.btn-primary {
  background: #1d439b !important;
  border-color: #1d439b !important;
}

body {
  background-color: #f9f9f9;
}

.section-wrapper {
  padding-bottom: 80px;
  padding-top: 80px;
}
.section-wrapper.is-light {
  background: #ffffff;
}
@media (max-width: 766px) {
  .section-wrapper {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.section-title {
  margin-bottom: 40px;
  text-align: center;
}
.section-title.is-single {
  margin-bottom: 0;
}
.section-title h2 {
  line-height: 1;
}
.section-title h4,
.section-title h5 {
  margin-top: 24px;
}
@media (max-width: 766px) {
  .section-title {
    margin-bottom: 0;
  }
}

.section-content h2 {
  margin-bottom: 16px;
}

#main {
  margin-top: 76px;
}

#header {
  background: #F3F3F3;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
#header .navbar-nav .nav-link {
  color: #333333;
  font-family: "Titillium Web", sans-serif !important;
  font-weight: 600;
  text-transform: uppercase;
}
#header .navbar-nav .nav-link.active {
  color: #1d439b;
}

.footlinks {
  background: #333333;
  color: #ffffff;
  padding: 80px 0;
}
.footlinks--content h4 {
  color: #f9f9f9;
  font-weight: 600;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.footlinks--content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footlinks--content ul li {
  margin-bottom: 8px;
}
.footlinks--content ul.list-links li {
  position: relative;
}
.footlinks--content ul.list-links li a:before {
  color: #fbd118;
  content: "";
  font-family: "Font Awesome 5 Pro" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-right: 8px;
}
.footlinks--content ul.list-social li {
  display: inline;
  margin-right: 16px;
}
.footlinks--content ul.list-social li i {
  font-size: 40px;
}
.footlinks--content a {
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}
.footlinks--content a:hover {
  opacity: 0.8;
}
@media (max-width: 766px) {
  .footlinks {
    padding: 40px 0;
  }
  .footlinks--content {
    border-bottom: 1px solid #999999;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
  .footlinks--content.is-last {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .footlinks--content ul.list-social li i {
    font-size: 32px;
  }
}

.copyright {
  background: #000000;
  color: #ffffff;
  font-size: 14px;
  padding: 24px 0;
}
.copyright a {
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}
.copyright a:hover {
  opacity: 0.8;
}
@media (max-width: 766px) {
  .copyright {
    text-align: center;
  }
}

.carousel--hero .carousel-item {
  height: calc(100vh - 150px);
}
.carousel--hero .carousel-item .carousel-caption {
  background: rgba(0, 0, 0, 0.8);
  border-top: 4px solid #fbd118;
  left: 18%;
  margin-bottom: 80px;
  right: 18%;
  padding: 24px 24px 16px;
  text-align: left;
}
.carousel--hero .btn-outline-primary {
  color: #fbd118;
  border-color: #fbd118;
}
.carousel--hero .btn-outline-primary:hover {
  color: #000000;
  background-color: #fbd118;
}
.carousel--hero .carousel-control-next-icon,
.carousel--hero .carousel-control-prev-icon {
  height: 4rem;
  width: 4rem;
}
@media (max-width: 766px) {
  .carousel--hero .carousel-item {
    height: calc(100vh - 100px);
  }
  .carousel--hero .carousel-item .carousel-caption {
    display: block !important;
    left: var(--bs-gutter-x, 0.75rem);
    right: var(--bs-gutter-x, 0.75rem);
  }
  .carousel--hero .carousel-item .carousel-item--image {
    width: auto !important;
  }
  .carousel--hero .carousel-control-next-icon,
.carousel--hero .carousel-control-prev-icon {
    display: none;
  }
}

.intro {
  background: #ffffff;
}
.intro--left h3 {
  color: #ce2028;
}
@media (max-width: 766px) {
  .intro {
    text-align: center;
  }
  .intro--left {
    margin-bottom: 40px;
  }
}

.services--card {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}
.services .card-content {
  padding: 24px 48px;
  text-align: center;
}
.services .card-content .btn {
  margin-top: 16px;
}

.steps .steps-card {
  border: 1px solid #fbd118;
  border-radius: 4px;
  margin-bottom: 24px;
}
.steps .steps-card:last-child {
  margin: 0;
}
.steps .steps-card > div {
  padding: 16px;
}
.steps .steps-card--no {
  align-items: center;
  background: #fbd118;
  color: #000000;
  display: flex;
  font-family: "Dosis", sans-serif !important;
  font-weight: 400;
  justify-content: center;
  width: 20%;
}
.steps .steps-card--text {
  width: 80%;
}
@media (max-width: 766px) {
  .steps {
    margin-top: 40px;
  }
}

.testimonials {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  min-height: 180px;
  padding: 24px;
  position: relative;
  text-align: center;
}
.testimonials span {
  display: block;
  font-family: "Dosis", sans-serif !important;
  font-weight: 300;
  font-style: italic;
  position: relative;
}
.testimonials span:before, .testimonials span:after {
  color: #999999;
  font-size: 24px;
  line-height: 1;
}
.testimonials span:before {
  content: "“";
}
.testimonials span:after {
  content: "”";
}

.breadcrumbs {
  background: #1d439b;
  color: #ffffff;
  padding: 18px 0;
}
.breadcrumbs--inner {
  font-size: 16px;
}
.breadcrumbs--inner .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}
.breadcrumbs--inner .links a {
  color: #ffffff;
  line-height: 1;
  opacity: 0.8;
  text-decoration: none;
}
.breadcrumbs--inner .links a:hover {
  opacity: 1;
}

.column-card {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}
.column-card .card-content {
  padding: 24px 48px;
  text-align: center;
}

.page-content h3 {
  margin-bottom: 16px;
}
.page-content h5 {
  font-family: "Titillium Web", sans-serif !important;
  font-weight: 600;
  margin-bottom: 16px;
}
@media (max-width: 766px) {
  .page-content img {
    margin-bottom: 24px;
  }
}

.map-fluid {
  height: 360px;
  overflow: hidden;
  position: relative;
}
.map-fluid iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.contact-box > .container {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.contact-box.is-form {
  padding-top: 0;
}
.contact-box.is-form form {
  padding: 24px 24px 32px;
}
.contact-card {
  padding: 24px 0;
}
.contact-icon {
  border: 2px solid #ce2028;
  border-radius: 50%;
  height: 42px;
  margin-right: 16px;
  padding: 8px 0;
  text-align: center;
  width: 42px;
}
.contact-icon i {
  color: #ce2028;
  font-size: 20px;
}
.contact-text h5 {
  font-weight: 600;
}
.contact-text p {
  margin: 0;
}
.contact-text a {
  color: #000000;
  opacity: 1;
  text-decoration: none;
}
.contact-text a:hover {
  opacity: 0.8;
}

.service-area {
  background: #fbd118;
  color: #000000;
  font-size: 20px;
  min-height: 80px;
  padding: 24px;
  text-align: center;
}

.bg-white {
  background-color: #ffffff;
}

.text-red {
  color: #ce2028;
}

.text-center {
  text-align: center;
}