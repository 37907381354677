// Colors
$black: #000000;
$white: #ffffff;
$dgrey: #333333;
$grey: #666666;
$lgrey: #999999;
$offwhite: #f1f1f1;
$offwhite2: #f9f9f9;
$offwhite3: #F3F3F3;
$blue: #1d439b;
$red: #ce2028;
$yellow: #fbd118;

// Breakpoints
$tablet-width: 767px;
$desktop-width: 1024px;

// Fonts
$dosis: "Dosis", sans-serif !important;
$tweb: "Titillium Web", sans-serif !important;

$fab: "Font Awesome 5 Brands" !important;
$fap: "Font Awesome 5 Pro" !important;

