h1,
h2,
h3,
h4,
h5,
h6 {
  @include dosis;
}

h1,
.h1 {
  font-size: 48px;

  @include mobile {
    font-size: 40px;
  }
}

h2,
.h2 {
  font-size: 40px;

  @include mobile {
    font-size: 32px;
  }
}

h3,
.h3 {
  font-size: 32px;

  @include mobile {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-size: 24px;

  @include mobile {
    font-size: 20px;
  }
}

h5,
.h5 {
  font-size: 20px;

  @include mobile {
    font-size: 18px;
  }
}

body {
  @include tweb;
}

// Buttons
.btn {
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }
}

.btn-primary {
  background: $blue !important;
  border-color: $blue !important;
}
